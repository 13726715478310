import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link} from "@mui/material";
import SignupForm from "../../components/SignupForm";
import Logo from "../../components/Logo";
import { motion } from "framer-motion";

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 40,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Signup = ({ setAuth }) => {
  return (
    <div className="root">
      <Container className="container" maxWidth="sm">
        <div className="card-content">
          <div className="topHeader">
            <img src="/static/1r-logo.png" />
            <span>Create a 1receipt account</span>
          </div>
          <div className="sub-content">
            <div className="heading" component={motion.div} {...fadeInUp}>
              <Logo />
            </div>

            <SignupForm setAuth={setAuth} />

            {/* Commented until fixed the internal redirect back to app */}
            {/* <Typography
              component={motion.p}
              {...fadeInUp}
              variant="body2"
              align="center"
              sx={{ mt: 5 }}
            >
              Already have an account?{" "}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to="/login"
                style={{ color: "#00C56E" }}
              >
                Sign in
              </Link>
            </Typography> */}

            <Typography
              component={motion.p}
              {...fadeInUp}
              variant="body2"
              align="center"
              sx={{ color: "#000", mt: 2 }}
            >
              By signing up, I agree to 1receipt's{" "}
              <Link
                underline="always"
                href="https://www.1receipt.io/privacy"
                style={{ color: "#00C56E" }}
              >
                privacy policy
              </Link>{" "}
              and{" "}
              <Link
                underline="always"
                href="https://www.1receipt.io/terms"
                style={{ color: "#00C56E" }}
              >
                terms of service
              </Link>.{" "}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Signup;
