import * as Yup from "yup";
import { useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  Box,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";
import authentication from "../services/AmplifyServices"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SignupForm = ({ setAuth }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const redirectURL = query.get('redirect');
    if (redirectURL !== '') {
      localStorage.setItem('redirectURL', redirectURL);
    }
  }, [search])
  const SignupSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async ({ fullName, email }) => {
      try {
        const sub = await authentication.signUp(email, fullName)
        console.log(`shopper created: ${sub}`)
        toast('Sign up successful. Please login!')      
        const query = new URLSearchParams(search);
        const storedRedirectURL = query.get('redirect') || '';
        console.log('Redirect URL:', storedRedirectURL);
        window.location = storedRedirectURL; // sign up is no longer redirect to login and it is separate operation and handled internally via the client app.
      } catch (err) {
        toast('An error has occurred.')
        console.log(err.message)
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={animate}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps("fullName")}
              error={Boolean(touched.fullName && errors.fullName)}
              helperText={touched.fullName && errors.fullName}
            />
          </Stack>

          <Stack
            spacing={3}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

          </Stack>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
          >
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              style={{
                backgroundColor: "#00C56E",
              }}
            >
              Sign up
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default SignupForm;
