import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";
import authentication from "../services/AmplifyServices"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './style.css'
 
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SigninForm = ({ setAuth }) => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const from = state?.from?.pathname || "/"

  useEffect(() => {
    const query = new URLSearchParams(search);
    const redirectURL = query.get('redirect');
    if (redirectURL !== '') {
      localStorage.setItem('redirectURL', redirectURL);
    }
  }, [search])

  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: SigninSchema,
    onSubmit: async ({ email }) => {
      console.log("submitting...");
      await authentication.signOut();
      try {
        await authentication.signIn(email);
        console.log("submitted!!");
        navigate('/otp', { state: { email } });
      } catch (err) {
        const query = new URLSearchParams(search);
        const storedRedirectURL = query.get('redirect') || '';
        console.log('Redirect URL:', storedRedirectURL);
        if (err.message === 'User does not exist.') {
          toast('Email not found. Please sign up.')
        }
        console.log(err.message);
        //navigate('/signup')
        setTimeout(() => {
          window.location = storedRedirectURL; // handled this exception internally via the client app.
        }, 1300); // Delay for 1.3 second
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email Address"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton className="otp-button" edge="end" color="primary">
                      <LoadingButton
                        
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        style={{
                          backgroundColor: "#00C56E",
                        }}
                      >
                        {isSubmitting ? "loading..." : "Get OTP"}
                      </LoadingButton>
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Box>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
            </Stack>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default SigninForm;
