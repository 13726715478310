import { useState } from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes  } from "react-router-dom"; 
import Signin from "./ui/Signin/Signin";
import Signup from "./ui/Signup/Signup";
import OTP from "./ui/OTP/OTP";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [auth, setAuth] = useState(false);
  console.log(auth)

  return (
    <>
      <CssBaseline />
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Signin setAuth={setAuth} />} />
        <Route path="/signup" element={<Signup setAuth={setAuth} />} />
        <Route path="/otp" element={<OTP setAuth={setAuth} />} />
        <Route
          path="/" 
          element={<Signin setAuth={setAuth}/>}
        />
        <Route 
          path="*"
          element={<Signin setAuth={setAuth}/>}
        />
      </Routes>
    </>
  );
}

export default App;
