import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link} from "@mui/material";
import SigninForm from "../../components/SigninForm";
import Logo from "../../components/Logo";
import { motion } from "framer-motion";
import "./style.css";

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Login = ({ setAuth }) => {
  return (
    <div className="root">
      <Container className="container" maxWidth="sm">
        <div className="card-content">
          <div className="topHeader">
            <img src="/static/1r-logo.png" alt="1receipt logo" />
            <span>Sign in with your 1receipt account</span>
          </div>
          <div className="sub-content">
            <div className="heading" component={motion.div} {...fadeInUp}>
              <Logo />
            </div>

            <SigninForm setAuth={setAuth} />
            
            {/* Commented until fixed the internal redirect back to app */}
            {/* <Typography
              component={motion.p}
              {...fadeInUp}
              variant="body2"
              align="center"
              className="footer-text"
            >
              Need an account?{" "}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to="/signup"
                style={{ color: "#00C56E" }}
              >
                Sign up
              </Link>
            </Typography> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
