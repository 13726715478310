import { Auth } from 'aws-amplify'

class Authentication {
  // User sign up
  async signUp(email, name) {
    console.log(email, name)
    try {
      let data = await Auth.signUp({
        username: email,
        password: `A${getRandomString(30)}!`,
        attributes: {
          email,
          name
        },
      })
      console.log('cognito sign up success')
      console.log(data)
      console.log(data.user)
      console.log(data.userSub)
      return data.userSub
    } catch (err) {
      console.error('error occurs when signing up in Cognito:', err)
      throw err
    }
  }

  // Resend verify code using username
  async answerCustomChallenge(answer) {
    // Send the answer to the User Pool
    // This will throw an error if it’s the 3rd wrong answer
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer);
    // It we get here, the answer was sent successfully,
    // but it might have been wrong (1st or 2nd time)
    // So we should test if the user is authenticated now
    try {
      // This will throw an error if the user is not yet authenticated:
      const currentSession = await Auth.currentSession();
      return {
        idToken: currentSession.getIdToken(),
        refreshToken: currentSession.getRefreshToken()
      }
    } catch (err) {
      console.log('Apparently the user did not enter the right code')
      throw err
    }
  }

  // User sign in
  async signIn(username) {
    this.cognitoUser = await Auth.signIn(username)
  }

  // User sign out
  async signOut() {
    try {
      // This will throw an error if the user is not yet authenticated:
      await Auth.currentSession();
      await Auth.signOut()
    } catch (err) {
      console.log(`Don't need to sign out`)
    }
  }
}

function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
}

function intToHex(nr) {
  return nr.toString(16).padStart(2, '0');
}

const authentication = new Authentication()
export default authentication;
