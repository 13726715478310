{
    /* Amplify Environment Configuration for App.js
    Development is 'false' and Production is 'true' */
}
export const PRODUCTION = false
let USER_POOL_ID = ''
let USER_POOL_WEB_CLIENT_ID = ''

if (PRODUCTION) {
    USER_POOL_ID = 'ap-southeast-2_j1aimrjgy'
    USER_POOL_WEB_CLIENT_ID = '21jhrjir8hol2r769c21i386as'
} else {
    USER_POOL_ID = 'ap-southeast-2_p3lTm2qe9'
    USER_POOL_WEB_CLIENT_ID = '3a36b6ik43n1dl91s8nsqs0cu7'
}
export default {
    USER_POOL_ID: USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID: USER_POOL_WEB_CLIENT_ID,
    REGION: 'ap-southeast-2'
}
