import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from 'aws-amplify'
import AmplifyEnv from "./constants/AmplifyEnv";
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV !== 'development') console.log = () => {}

Amplify.configure({
  Auth: {
    // Amazon Cognito Region
    region: AmplifyEnv.REGION,
    // Amazon Cognito User Pool ID
    userPoolId: AmplifyEnv.USER_POOL_ID,
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: AmplifyEnv.USER_POOL_WEB_CLIENT_ID,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: AmplifyEnv.DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: AmplifyEnv.REDIRECT_URL,
      redirectSignOut: AmplifyEnv.REDIRECT_URL,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
