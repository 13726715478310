import React, { useState } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input'
import {
  Box,
  Stack,
} from "@mui/material";
import { motion } from "framer-motion";
import authentication from "../services/AmplifyServices"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const OTPForm = ({ setAuth }) => {

  const [otp, setOTP] = useState('');
  const handleChange = async (newValue) => {
    setOTP(newValue);
    console.log(newValue);
    if ((/^[0-9]{4}$/).test(newValue)) {
      console.log("submitting...");
      try {
        const { idToken, refreshToken } = await authentication.answerCustomChallenge(`${newValue}`)
        console.log("submitted!!");
        setAuth(true);
        const redirectURL = `${localStorage.getItem('redirectURL')}?idToken=${idToken.getJwtToken()}&refreshToken=${refreshToken.getToken()}`;
        localStorage.removeItem('redirectURL')
        window.location = redirectURL;
      } catch (err) {
        toast('Please enter the right code.');
      }
    }
  }

  return (
    <Box
      component={motion.div}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "3rem"
      }}
      animate={{
        transition: {
          staggerChildren: 0.55,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        component={motion.div}
        initial={{ opacity: 0, y: 40 }}
        animate={animate}
      >

        <MuiOtpInput
          TextFieldsProps={{
            type: 'number',
            inputProps: {
              inputMode: 'numeric',
              pattern: '[0-9]*',
            },
            size: 'medium',
          }}
          value={otp}
          onChange={handleChange}
        />

      </Box>

      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={animate}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
        </Stack>
      </Box>
    </Box>
  );
};

export default OTPForm;
